import { graphql, useStaticQuery } from 'gatsby';

export const usePriceMetaData = () => {
  const site = useStaticQuery(graphql`
    query PricesQuery {
      wpPage(id: { eq: "cG9zdDoxNDk3" }) {
        id
        TalxisLicences {
          headerLt
          descriptionLt
          disclaimerLt
          tableLt {
            field
            thead
          }
        }
        TalxisManagedServices {
          headerMs
          descriptionMs
          disclaimerMs
          tableMs {
            field
            thead
          }
        }
        TalxisInstallPackages {
          fieldGroupName
          headerTp
          rowsTp {
            advantageTp
            baseTp
            headerColTp
            standardTp
          }
        }
        TalxisTelefonieDescriptionTable {
          description
          disclaimer
          header
          rowsTalxisinstallpackages {
            advantage
            base
            fieldGroupName
            standard
            headerCol
          }
        }
        TalxisManagedServicesDescription {
          headerMsd
          rowsTalxismanagedservicesdescription {
            advantage
            base
            headerCol
            included
            standard
          }
        }
        Instalall_packages {
          descriptionip
          headerip
          disclaimerip
          table {
            field
            thead
          }
        }
      }
    }
  `);
  return site;
};
