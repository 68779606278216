import { usePriceMetaData } from '../hooks/cs/prices-data.metadata';

export function pricesMetaData(lang = 'cs'): any {
  const data = usePriceMetaData();

  if (data.wpPage.Instalall_packages) {
    data.wpPage.Instalall_packages.tablerows = mapVerticalTable(
      data.wpPage.Instalall_packages.table,
    );
  }

  if (data.wpPage.TalxisManagedServices) {
    data.wpPage.TalxisManagedServices.tablerows = mapVerticalTable(
      data.wpPage.TalxisManagedServices.tableMs,
    );
  }

  if (data.wpPage.TalxisLicences) {
    data.wpPage.TalxisLicences.tablerows = mapVerticalTable(data.wpPage.TalxisLicences.tableLt);
  }

  return data?.wpPage;
}

function mapVerticalTable(data: any) {
  let theads: string[] = [];
  let rows: string[] = [];

  data.map((item: any) => {
    theads.push(item.thead);
    rows.push(item.field);
  });

  return [[...theads], [...rows]];
}
