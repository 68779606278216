import LayoutProductPage from '../components/layouts/ProductPage';
import * as styleProduct from '../styles/pages/productIndex.module.scss';
import * as style from '../styles/pages/product.module.scss';
import * as gstyle from '../styles/styles.module.scss';
import Footer from '../components/Footer';
import * as tstyle from '../styles/priceTable.module.scss';
import { Helmet } from 'react-helmet';
import React from 'react';
import classnames from 'classnames';
import { pricesMetaData } from '../data/page-prices.data';

export default function prices() {
  const installPackages = pricesMetaData()?.Instalall_packages;
  const talxisManagedServices = pricesMetaData()?.TalxisManagedServices;
  const talxisLicences = pricesMetaData()?.TalxisLicences;
  const packagesDescription = pricesMetaData()?.TalxisInstallPackages;
  const managedServices = pricesMetaData()?.TalxisManagedServicesDescription;
  const telephoniePrices = pricesMetaData()?.TalxisTelefonieDescriptionTable;

  return (
    <section className={style.purple}>
      <Helmet title="TALXIS Ceník"> </Helmet>
      <LayoutProductPage
        header="TALXIS"
        copy="Představte si informační systém, který si poskládáte podle vlastních představ a upravíte na míru přímo Vašim potřebám."
        subHeader="Ceník"
        color="purple"
        backLink="/products"
      >
        <section className={classnames(styleProduct.productDetail, styleProduct.purple)}>
          <section>
            <div className={classnames(gstyle.center, styleProduct.center)}>
              <section className={tstyle.priceSection}>
                <h1>{installPackages.headerip}</h1>
                <p dangerouslySetInnerHTML={{ __html: installPackages.descriptionip }}></p>
                <div className={tstyle.priceTableMain}>
                  <table className={tstyle.table}>
                    {installPackages.tablerows.map((row: any, key: number) => (
                      <tr key={key}>
                        {row.map((field: any, key: number) => (
                          <td key={key}>{field}</td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
                <p
                  className={tstyle.dodatek}
                  dangerouslySetInnerHTML={{ __html: installPackages.disclaimerip }}
                ></p>
                <h2>{packagesDescription.headerTp}</h2>
                <div className={tstyle.priceTableMain}>
                  <table
                    className={classnames(
                      tstyle.table,
                      tstyle.uniqueColors,
                      tstyle.installpackages,
                    )}
                  >
                    <tr>
                      {installPackages.tablerows[0].map((field: any, key: number) => {
                        return key == 0 ? (
                          <td key={key} style={{ width: '50%' }}></td>
                        ) : (
                          <td key={key}>{field}</td>
                        );
                      })}
                    </tr>
                    {packagesDescription.rowsTp.map((row: any, key: number) => (
                      <tr key={key}>
                        <td>{row.headerColTp}</td>
                        <td className={row.baseTp ? tstyle.yes : null}>
                          {row.baseTp ? 'ANO' : 'NE'}
                        </td>
                        <td className={row.standardTp ? tstyle.yes : null}>
                          {row.standardTp ? 'ANO' : 'NE'}
                        </td>
                        <td className={row.advantageTp ? tstyle.yes : null}>
                          {row.advantageTp ? 'ANO' : 'NE'}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <h1>{talxisLicences.headerLt}</h1>
                <p dangerouslySetInnerHTML={{ __html: talxisLicences.descriptionLt }}></p>
                <div className={tstyle.priceTableMain}>
                  <table className={tstyle.table}>
                    {talxisLicences.tablerows.map((row: any, key: number) => (
                      <tr key={key}>
                        {row.map((field: any, key: number) => (
                          <td key={key}>{field}</td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
                <p
                  className={tstyle.dodatek}
                  dangerouslySetInnerHTML={{ __html: talxisLicences.disclaimerLt }}
                ></p>
                <h1>{talxisManagedServices.headerMs}</h1>
                <p dangerouslySetInnerHTML={{ __html: talxisManagedServices.descriptionMs }}></p>
                <div className={tstyle.priceTableMain}>
                  <table className={tstyle.table}>
                    {talxisManagedServices.tablerows.map((row: any, key: number) => (
                      <tr key={key}>
                        {row.map((field: any, key: number) => (
                          <td key={key}>{field}</td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
                <p
                  className={tstyle.dodatek}
                  dangerouslySetInnerHTML={{ __html: talxisManagedServices.disclaimerMs }}
                ></p>
                {/* <h2>{managedServices.headerMsd}</h2>
                <div className={tstyle.priceTableMain}>
                  <table
                    className={classnames(
                      tstyle.table,
                      tstyle.uniqueColors,
                      tstyle.managedServices,
                    )}
                  >
                    <tr>
                      <td style={{ width: '30%' }}>
                        <td></td>
                      </td>
                      <td>Součástí produktu</td>
                      <td>Základní / Basic</td>
                      <td>Standardní / Standard</td>
                      <td>Nadstandardní / Premier</td>
                    </tr>
                    {managedServices.rowsTalxismanagedservicesdescription.map(
                      (row: any, key: number) => (
                        <tr key={key}>
                          <td>{row.headerCol}</td>
                          <td className={row.included != 'Ne' ? tstyle.yes : null}>
                            {row.included}
                          </td>
                          <td className={row.base != 'Ne' ? tstyle.yes : null}>{row.base}</td>
                          <td className={row.standard != 'Ne' ? tstyle.yes : null}>
                            {row.standard}
                          </td>
                          <td className={row.advantage != 'Ne' ? tstyle.yes : null}>
                            {row.advantage}
                          </td>
                        </tr>
                      ),
                    )}
                  </table>
                </div> */}
                <h1>{telephoniePrices.header}</h1>
                <p dangerouslySetInnerHTML={{ __html: telephoniePrices.description }}></p>
                <div className={tstyle.priceTableMain}>
                  <table className={classnames(tstyle.table)}>
                    <tr>
                      <td style={{ width: '30%' }}>TALXIS Telefonní systém *</td>
                      <td>BASIC</td>
                      <td>STANDARD</td>
                      <td>PREMIUM</td>
                    </tr>
                    {telephoniePrices.rowsTalxisinstallpackages.map((row: any, key: number) => (
                      <tr key={key}>
                        <td>{row.headerCol}</td>
                        <td>{row.base}</td>
                        <td>{row.standard}</td>
                        <td>{row.advantage}</td>
                      </tr>
                    ))}
                  </table>
                  <p
                    dangerouslySetInnerHTML={{ __html: telephoniePrices.disclaimer }}
                    className={tstyle.dodatek}
                  ></p>
                </div>
              </section>
            </div>
          </section>
        </section>
      </LayoutProductPage>
      <section className={styleProduct.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
